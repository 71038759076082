import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = ['trigger', 'qrcode']

  connect () {
    console.log("shareoverlay")
  }

  showOverlay (e) {
    let mc = this.getModalController();
    let qr = this.qrcodeTarget.cloneNode(true)
    let div = document.createElement('div')
    let adiv = document.createElement('div')
    div.classList= "mx-auto text-center h-100";

    adiv.innerHTML = '<a href="' + this.element.dataset.shareurl + '">' + this.element.dataset.shareurl + "</a>"
    adiv.classList = "h1"
    qr.classList.remove("d-none")
    qr.classList.add("h-75")

    div.appendChild(qr)
    div.appendChild(adiv)

    mc.setContent(div)
    mc.showFullscreen(e)
  }
}