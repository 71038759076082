import ApplicationController from './application_controller'
import { createPopper } from '@popperjs/core';
import * as bootstrap from 'bootstrap';

export default class extends ApplicationController {

  static targets = [ "trigger", "tooltip" ]

  connect () {
    super.connect()

    if(this.triggerTarget !== undefined) {
      this.triggerTarget.setAttribute("data-bs-html", true);
      this.tooltip = new bootstrap.Tooltip(this.triggerTarget);
    }
  }
}
