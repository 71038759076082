import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect() {}

  toogleWs() {
    let meta_ws_tag = document.querySelector(
      'meta[name="websocket_force_disabled"]',
    );
    let stat = meta_ws_tag.content;
    meta_ws_tag.content = stat == 1 ? 0 : 1;
    super.styleBodyDisconnected();

    console.log(
      "meta_ws_tag.content: " +
        meta_ws_tag.content +
        " super.isConnected: " +
        (super.isConnected() ? "true" : "false"),
    );
  }
}
