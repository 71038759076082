import ApplicationController from './application_controller'
import '~/stylesheets/slider.scss'
import anime from 'animejs/lib/anime.es.js';

export default class extends ApplicationController {

  static targets = [ "image" ]

  connect () {
    super.connect()

    this.imageCount = this.imageTargets.length
    this.currentImage = 0
    this.maxHeight = 0
    this.parent = this.element.parentNode

    if(this.imageCount < 2) {
      return true
    }

    // console.log("slider_contrller.js connected with " + this.imageCount + " images")
    // console.log("parent [" + this.parent.offsetWidth + "/" + this.parent.offsetHeight + "]")

    for (var i = 0; i < this.imageTargets.length; i++) {
      if (i > 0) {
        this.imageTargets[i].classList.add("d-none")
      }
    }

    this.timer = setInterval(this.transition.bind(this), 2500)
  }

  setMaxHeight (event) {
    console.log("run slider_controller::setMaxHeight")
    let img = event.target
    let imageRatio = this.parent.offsetWidth / img.width
    let targetHeight = img.height * imageRatio

    if (targetHeight > this.maxHeight && img.width > this.parent.offsetWidth) {
      this.maxHeight = targetHeight
      this.element.style.height = this.maxHeight + "px";
    }
  }
  

  transition () {
    if(this.imageTargets == undefined || this.imageCount == 0) {
      return true;
    }

    this.fadeOut(this.imageTargets[this.currentImage], this.nextSlide.bind(this))
  }

  nextSlide (anim) {
    // hide the previous image 
    this.imageTargets[this.currentImage].classList.add("d-none")

    if(this.currentImage == (this.imageCount - 1)) {
      this.currentImage = 0
    } else {
      this.currentImage = this.currentImage + 1
    }

    // this.imageTargets[this.currentImage].classList.remove("d-none")
    this.fadeIn(this.imageTargets[this.currentImage])
  }

  fadeOut (trg, completeFn) {
    anime({
      targets: this.imageTargets[this.currentImage],
      opacity: 0,
      duration: 3000,
      begin: function(anim) {
        trg.classList.remove("d-none")
        trg.style.opacity = 1
      }.bind(trg),
      complete: completeFn
    })
  }
  fadeIn (trg) {
    anime({
      targets: trg,
      opacity: 1,
      duration: 3000,
      begin: function(anim) {
        trg.style.opacity = 0
        trg.classList.remove("d-none")
      }.bind(trg)
    });
  }
}
