import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ "trigger", "menu" ]

  connect () {
    super.connect()
  }

  dropdownshow (event) {
    event.preventDefault()
    this.hideAllDropdowns()
    this.menuTarget.classList.toggle("show")
  }

  dropdownhide (event) {
    event.preventDefault()
    // console.log("hide tooltip for '" + this.triggerTarget.dataset['title'] + "'")
    // console.log("\ttarget: " + event.target)

    clearInterval(self.timer)
    this.fadeOut(this.menuTarget)
  }


  positionDropdown () {
    var viewportOffset = this.triggerTarget.getBoundingClientRect();
    var top = viewportOffset.top;
    var left = viewportOffset.left;
/*
    this.menuTarget.style.top = (window.scrollY + viewportOffset.top + 32) + "px"
    this.menuTarget.style.left = (window.scrollX + viewportOffset.left + viewportOffset.width / 2 - (this.menuTarget.getBoundingClientRect().width / 2)) + "px"*/


  }

  isChild (tester) {
    while (tester != null) {
      if (tester === this.triggerTarget) {
        return tester;
      }
      tester = tester.parentElement
    }
    return false
  }
}
