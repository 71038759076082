import ApplicationController from './application_controller'
// import Stimulus from 'stimulus';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "alert", "close" ]

  connect () {
    /*
     * If there are alertTargets on the page rendered from the backend application 
     * set the fade out timer for them to make them disappear after a while.
     */
    if (this.alertTargets.length > 0) {
      for (var i = 0; i < this.alertTargets.length; i++) {
        let a = this.alertTargets[i];
        setTimeout(function () { this.fadeOut(a) }.bind(this, a), 3000)
      }
    }

    document.addEventListener("flashMessage", this.didReceiveFlashNotification.bind(this))
  }
  close (event) {
    event.preventDefault()
    this.fadeOutAlert()
  }
  fadeOutAlert () {
    if(this.alertTarget && this.alertTarget !== undefined) {
      this.fadeOut (this.alertTarget)
    }
  }

  /*
   * This handles notifications sent from e.g. ApplicationReflex::no_access_modal method
   */
  didReceiveFlashNotification (event) {
    const { key, msg } = event.detail

    this.getModalController().close()
    let alertElement = this.createAlert(key, msg)
    this.element.insertAdjacentElement("afterbegin", alertElement)

    setTimeout(function () { this.fadeOut (alertElement) }.bind(this, alertElement), 3000)
  }

  createAlert (key, msg) {
    var alertElement = document.createElement("div")
    alertElement.classList = "alert alert-warning"
    alertElement.innerHTML = "<button type=\"button\" class=\"close btn\" data-action=\"click->flash#close\" data-flash-target=\"close\" data-dismiss=\"alert\" aria-label=\"close\"><span aria-hidden=\"true\">&times</span></button><h6>" + msg + "</h6></div>";
    return alertElement;
  }
}
