import ApplicationController from "./application_controller";

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["qrcode"];

  connect() {}
  showQRCodes(event) {
    event.preventDefault();
    for (var i = 0; i < this.qrcodeTargets.length; i++) {
      this.qrcodeTargets[i].classList.toggle("d-none");
    }
  }
  print() {
    window.print();
  }
}
