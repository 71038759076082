import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';
import FileSaver from "file-saver";

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "form" ]

  connect () {
    StimulusReflex.register(this)
  }
  exportMd (event) {
    event.preventDefault();
    this.pushDownload();
  }
  async pushDownload () {
    const mddata = await this.loadMarkdown();
    var blob = new Blob([mddata.md], {type: "text/plain;charset=utf-8"});
    await saveAs(blob, mddata.title + ".md");
  }
  async loadMarkdown () {
    let response = await fetch('/boards/' + this.element.dataset.boardId + "/md");
    let md = await response.text();
    let filename = md.match(/(?:^#*)(.*$)/m)[1];
    let mddata = {
      "title": filename.trim(),
      "md": md
    };
    return mddata
  }
}
