import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

import "~/stylesheets/board_containers.scss";

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  static targets = ["containers"];

  connect() {
    super.connect();
    StimulusReflex.register(this);
    this.element[this.identifier] = this;

    this.selection = [];
    this.altModifierKeyPressed = false;

    document.addEventListener(
      "keydown",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = true;
        }
      }.bind(this),
    );
    document.addEventListener(
      "keyup",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = false;
        }
      }.bind(this),
    );

    console.log("boardindex_controller.js connected");
  }

  new(event) {
    let button = this.getParent(event.target, "btn");

    if (super.isConnected(event)) {
      event.preventDefault();
      event.stopPropagation();

      this.clearModalContent();
      this.getModalController().show(event);
      this.getModalController().hideFooter();

      this.stimulate("BoardIndexReflex#newContainer", button);
    }
  }
  edit(event) {
    event.preventDefault();
    let button = this.getParent(event.target, "btn");

    this.clearModalContent();
    this.getModalController().show(event);
    this.getModalController().hideFooter();

    this.stimulate("BoardIndexReflex#editContainer", button);
  }
  save(event) {
    event.preventDefault();
    let button = this.getParent(event.target, "btn");
    let title = document.querySelector("#board_container_title").value;
    this.getModalController().close();
    this.stimulate("BoardIndexReflex#saveContainer", button, title);
  }
  deleteItem(event) {
    event.preventDefault();
    let item = this.getParent(event.target, "type", "dataset");
    const ok = confirm("Are you sure you would like to delete this item?");
    if (ok) {
      this.stimulate("BoardIndexReflex#deleteItems", event.target, {
        deleteItems: [[item.dataset.type, item.dataset.id]],
      });
    }
  }
  deleteItems(event) {
    let countItems = this.selection.length;
    if (countItems == undefined || countItems == 0) {
      alert("No items selected!");
      return false;
    }
    const ok = confirm(
      "Are you sure you would like to delete " + countItems + " items?",
    );
    if (ok) {
      this.stimulate("BoardIndexReflex#deleteItems", event.target, {
        deleteItems: this.getSelectedItems(),
      });
    }
  }
  drag(event) {
    event.preventDefault();
    this.dragItem = this.getParent(event.target, "list-group-item");

    this.dragItem.classList.add("opacity-50");
    for (var i = 0; i < this.selection.length; i++) {
      this.selection[i].classList.add("opacity-50");
    }
  }
  dragEnd(event) {
    event.preventDefault();
    this.dragItem.classList.remove("opacity-50");
    for (var i = 0; i < this.selection.length; i++) {
      this.selection[i].classList.remove("opacity-50");
    }
    this.disableAllDragovers();
  }
  disableAllDragovers() {
    let dragoverItems = document.querySelectorAll("boardindex-dragover");
    for (var i = 0; i < dragoverItems.length; i++) {
      this.removeClasses(dragoverItems[i], [
        "boardindex-dragover",
        "bg-warning",
      ]);
    }
  }
  dragOverContainer(event) {
    event.preventDefault();
    this.disableAllDragovers();
    this.dragOverTarget = this.getParent(
      event.target,
      "type",
      "dataset",
      "BoardContainer",
    );
    if (
      !this.dragOverTarget ||
      (this.dragOverTarget.dataset.type == this.dragItem.dataset.type &&
        this.dragOverTarget.dataset.id == this.dragItem.dataset.id)
    ) {
      console.log("no dragoverTarget");
      return false;
    }
    if (this.dragOverTarget.dataset.type == "BoardContainer") {
      this.addClasses(this.dragOverTarget, [
        "boardindex-dragover",
        "bg-warning",
      ]);
    }
  }
  dragLeaveContainer(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.dragOverTarget) {
      this.removeClasses(this.dragOverTarget, [
        "boardindex-dragover",
        "bg-warning",
      ]);
    }
    if (this.getParent(event.target, "boardcontainer-card")) {
      this.removeClasses(this.getParent(event.target, "boardcontainer-card"), [
        "boardindex-dragover",
        "bg-warning",
      ]);
    }
  }

  dropOnContainer(event) {
    event.stopPropagation();
    event.preventDefault();

    let dropTarget = this.getParent(
      event.target,
      "type",
      "dataset",
      "BoardContainer",
    );
    let moveItems = this.getSelectedItems();
    moveItems.push([this.dragItem.dataset.type, this.dragItem.dataset.id]);

    // console.log("dropped: " + this.dragItem.dataset.type + ":" + this.dragItem.dataset.id + " on: BoardContainer:" + dropTarget.dataset.id)
    this.stimulate("BoardIndexReflex#dropOnContainer", dropTarget, {
      moveItems: moveItems,
    });
  }
  getSelectedItems() {
    let selectedItems = [];
    for (var i = 0; i < this.selection.length; i++) {
      selectedItems.push([
        this.selection[i].dataset.type,
        this.selection[i].dataset.id,
      ]);
    }
    return selectedItems;
  }
  select(event) {
    let container = this.getParent(event.target, "type", "dataset");
    let selectedArrayItem = [container.dataset.type, container.dataset.id];
    let checkboxLabel = event.target.nextSibling;

    if (event.target.checked) {
      this.selection.push(container);
      checkboxLabel.classList.add("boardcontainer-highlight");
    } else {
      this.selection = this.selection.filter((item) => item !== container);
      checkboxLabel.classList.remove("boardcontainer-highlight");
    }
    // console.log("selection: " + this.selection)
  }
  keydown(event) {
    var code = event.keyCode;
    let listenKeys = { 18: "alt" };

    this.altModifierKeyPressed = true;
  }

  keyup(event) {
    var code = event.keyCode;
    let listenKeys = { 18: "alt" };
    // console.log("key up" + code);
    this.altModifierKeyPressed = false;
  }
}
