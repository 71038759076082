import ApplicationController from './application_controller'
import '/stylesheets/copytoclipboard.scss'

export default class extends ApplicationController {

  static targets = [ "input", "button" ]

  connect () {
    super.connect()
    console.log("copytoclipboard_controller.js connected")
  }

  copy (event) {
    navigator.clipboard.writeText(this.inputTarget.value).then(res=>{
      console.log("Input data copied to clipboard successfully '" + this.inputTarget.value + "'");
    })
  }
}