import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "board", "addform", "bodyTextarea" ]

  connect () {
    StimulusReflex.register(this)
  }

  display (event) {
    var modalController = document.querySelector("#defaultModal").modal

    modalController.show(event)
    modalController.hideFooter()
    this.stimulate('Changelog#display', this.element);
  }

  showVersion (event) {
    this.stimulate('Changelog#showVersion', this.element, event.target.value);
  }
}