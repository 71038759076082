import ApplicationController from "./application_controller";
import ZipCodeValidator from "../validators/zipcode_validator";

export default class extends ApplicationController {
  static targets = [
    "pricebox",
    "customeremail",
    "countrycodeselect",
    "postalcodeinput",
  ];

  connect() {
    super.connect();
    console.log("license controller connected");
    this.paddle_product_id = this.element.dataset["paddleProductId"];
    this.paddle_client_token = this.element.dataset["paddleToken"];

    if (this.element.dataset["paddleSandbox"] == "1") {
      Paddle.Environment.set("sandbox");
    } else {
      Paddle.Environment.set("production");
    }
    console.log(`Initialize Paddle with token ${this.paddle_client_token}`);
    Paddle.Initialize({
      token: this.paddle_client_token,
      eventCallback: this.handleEvent,
    });
    if (this.element.dataset.transaction != undefined) {
      Paddle.Checkout.open({
        transactionId: this.element.dataset.transaction,
      });
    }
  }

  handleEvent(eventData) {
    console.log("LicenseController.js received event " + eventData.name);
    if (eventData.name == "checkout.loaded") {
    }
    if (eventData.name == "checkout.closed") {
      console.log("\tcheckout closed" + JSON.stringify(eventData));
    }
  }

  openCheckout(e) {
    e.stopPropagation();
    e.preventDefault();

    // console.log(`openCheckout: dataset: ${e.target.dataset}`);
    let priceId = e.target.dataset.price;
    let customerId = e.target.dataset.customer;
    let customerEmail = this.customeremailTarget.innerText;
    let countryCode = this.countrycodeselectTarget.value;
    let postalCode = this.getPostalCode();

    var customerObject = {};

    if (!postalCode) {
      alert("Please validate PostalCode");
      this.postalcodeinputTarget.classList.add("invalid");
      return false;
    }
    this.postalcodeinputTarget.classList.remove("invalid");

    if (customerId != undefined && customerId != 0) {
      customerObject = {
        id: customerId,
        address: {
          countryCode: countryCode,
          postalCode: postalCode,
        },
      };
    } else {
      customerObject = {
        email: customerEmail,
        address: {
          countryCode: countryCode,
          postalCode: postalCode,
        },
      };
    }

    let items = [
      {
        priceId: priceId,
        quantity: 1,
      },
    ];
    Paddle.Checkout.open({ items: items, customer: customerObject });
  }

  updatePayment(event) {
    let tid = event.target.dataset.transaction;
    console.log("tid: " + tid);
    Paddle.Checkout.open({ transactionId: tid });
  }
  getPostalCode() {
    let v = new ZipCodeValidator();
    let countryCode = this.countrycodeselectTarget.value;
    let postalCode = this.postalcodeinputTarget.value;
    if (v.validate(postalCode, countryCode)) {
      return postalCode;
    } else {
      return false;
    }
  }
}
