import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [];

  connect() {
    // console.log("vcard_controller connected");
  }

  download(event) {
    event.preventDefault();
    this.pushDownload();
  }

  async pushDownload() {
    const vcarddata = await this.loadData();
    if (!vcarddata) {
      return false;
    } else {
      var blob = new Blob([vcarddata.data], {
        type: "text/plain;charset=utf-8",
      });
      await saveAs(blob, vcarddata.title + ".vcf");
    }
  }

  async loadData() {
    let postid = this.element.dataset.postId;
    let url = "/vcard_posts/" + postid + "/download";
    let method = "POST";

    let raw_body = {
      remote: true,
    };
    let headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    raw_body.authenticity_token = super.getCsrfToken();
    headers["authenticity_token"] = super.getCsrfToken();

    let body = JSON.stringify(raw_body);

    const response = await this.doFetch(url, method, body, headers);
    const raw_string = await response.text();

    if (!response.ok) {
      alert("An error occured when downloading \n" + response.statusText);
      return false;
    }

    var filename = "standardfilename";
    if (raw_string.match(/(?:^FN:)(.*)$/m)) {
      console.log("did match");
      filename = raw_string.match(/(?:^FN:)(.*)$/m)[1];
      filename = filename.toLowerCase();
      filename = filename.replaceAll(/\s/gi, "_");
    }

    let vcarddata = {
      title: filename.trim(),
      data: raw_string,
    };
    return vcarddata;
  }
  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
}
