import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "form" ]

  connect () {
    StimulusReflex.register(this)
  }
  beforeReflex(anchorElement) {
    this.hideAllFloatedElements()
  }
  subscribe (event) {
    event.preventDefault()

    if(!event.target.dataset['board-id']) {
      var etarget = this.getParent(event.target, 'button', 'tagname')
    } else {
      var etarget = event.target
    }

    this.clearModalContent()
    this.getModalController().show(event)
    this.getModalController().hideFooter()

    this.stimulate('BoardsubscriptionReflex#showBoardSubscriptions', etarget)
  }
  toggle_subscribe(event) {
    this.stimulate('BoardsubscriptionReflex#toggleSubscribe', event.target)
  }
  subscribeShare(event) {
    let subscribeEmail = document.querySelector("#subscribe-email").value
    this.stimulate('BoardsubscriptionReflex#subscribeShare', event.target, subscribeEmail)
  }
}
