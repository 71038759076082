import { Controller } from "stimulus";
import EasyMDE from "easymde";

import "easymde/dist/easymde.min.css";

export default class extends Controller {
  static targets = ["easymde"];

  connect() {
    this.element[this.identifier] = this;

    this.mde = new EasyMDE({
      autoDownloadFontAwesome: true,
      element: this.easymdeTarget,
      promptURLs: true,
      maxHeight: "600px",
      lineNumbers: false,
      spellChecker: false,
      toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "horizontal-rule",
        /*{
          name: "todo-list",
          action: (editor) => {
              var cm = editor.codemirror;
              cm._toggleLine(cm, 'todo-list', '- [ ]')
            },
          className: "fa fa-bolt",
          title: "Bold",
        },*/
        "|",
        "link",
        "image",
        "table",
        "|",
        "preview",
        "guide",
      ],
    });
  }

  getMde() {
    return this.mde;
  }
}
