// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//

// import Rails from '@rails/ujs';
// Rails.start();

// import "../icons";

import * as Turbo from '@hotwired/turbo'
Turbo.start()


// import "../channels/flash_channel.js"
// import "../controllers/index.js"


//
// import { ActiveStorage } from '@rails/activestorage'
// ActiveStorage.start()
//

// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Import all controllers.
// const controllers = import.meta.globEager('./**/*_controller.js')

// Example: Import a stylesheet in app/frontend/index.css
// @import 'bootstrap/scss/bootstrap';
// @import 'bootstrap-icons/font/bootstrap-icons';

import 'bootstrap/scss/bootstrap.scss'
import '~/stylesheets/devise.scss'

import '~/stylesheets/application.scss'

import '~/fonts/inter/inter.css'
import 'bootstrap-icons/font/bootstrap-icons.scss'

import "../controllers"
import "../config"
import "../channels"
