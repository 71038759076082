import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "select", "cardsrc" ]

  connect () {
    StimulusReflex.register(this)
    // console.log("postbatchcadd_controller.js did connect")
  }
  changedSeparator () {
    // console.log("did changedSeparator " + this.selectTarget)
  }
  hideAddCardForms (event) {
    this.getModalController().close()
  }
  batchSaveCards (event) {
    event.preventDefault()

    let columnId = this.cardsrcTarget.dataset.columnId;
    let lines = this.cardsrcTarget.value.split("\n");
    let styleId = this.element.querySelector("#style_palette_id").value;

    // console.log("lines: " + lines.count + " columnId: " + columnId + " styleId: " + styleId);
    //   def saveCard(columnId, postType, newBody, stylePalette, newMeta=nil)
    this.stimulate('BoardsReflex#batchSaveCards', event.target, columnId, "Post", this.cardsrcTarget.value, styleId, this.selectTarget.value)
    this.getModalController().close()
  }
  
}
