import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "modal", "dialog", "closeButton", "saveButton", "modalBody", "title", "footer" ]

  connect () {
    this.element[this.identifier] = this
    this.closeEventHandlers = [];
  }
  showFullscreen (event) {
    this.dialogTarget.classList.remove('modal-xl')
    this.dialogTarget.classList.add('modal-fullscreen')
    this.show(event)
  }
  show (event) {
    event.preventDefault()
    event.stopPropagation();

    this.closeEventHandlers = [];

    this.hideAllFloatedElements()
    this.modalTarget.style.display = "block"
    this.modalTarget.style.opacity = "1"
    this.modalTarget.classList.add("show")
    this.modalTarget.classList.remove("hide")
  }
  close () {
    this.modalTarget.style.display = "none"
    this.modalTarget.style.opacity = "0"
    this.modalTarget.classList.remove("show")
    this.modalTarget.classList.add("hide")

    // clean up fullscreen mode
    this.dialogTarget.classList.add('modal-xl')
    this.dialogTarget.classList.remove('modal-fullscreen')

    for (var i = 0; i < this.closeEventHandlers.length; i++) {
      this.closeEventHandlers[i]();
    }
  }
  setTitle (nTitle) {
    this.titleTarget.innerText = nTitle
  }
  setContent (nElement) {
    this.clearContent(this.modalBodyTarget)
    this.modalBodyTarget.appendChild(nElement)
  }
  setFooter (nElement) {
    this.clearContent(this.footerTarget)
    this.footerTarget.appendChild(nElement)
  }
  clearContent (trgElement) {
    if(trgElement == undefined) {
      trgElement = this.modalBodyTarget
    }
    while (trgElement.firstChild) {
      trgElement.removeChild(trgElement.firstChild);
    }
  }
  showFooter () {
    this.footerTarget.style.display = "block"
  }
  hideFooter () {
    console.log("hiding footer…")
    this.footerTarget.style.display = "none"
  }
  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="posts" to your markup alongside
   * data-reflex="Posts#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Posts#dance" data-controller="posts">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Posts#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Posts#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = 'Danced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "Couldn't dance!"
  // }
}
