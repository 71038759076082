import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";
import EasyMDE from "easymde";
// import 'easymde/dist/easymde.min.css'

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  static targets = ["container", "select", "description"];
  static values = {
    robjclass: String,
    robjid: Number,
  };

  connect() {
    StimulusReflex.register(this);
  }

  closeDropDown() {
    document.getElementById("board-styles-dropdown").classList.remove("show");
  }

  async assignPalette(event) {
    console.log("stylepalettes_controller - assignPalette");
    let btn = this.getParent(event.target, "button", "tagname");
    let dataprovider = this.getParent(event.target, "stylableid", "dataset");
    let paletteid = btn.dataset.stylepaletteid;

    if (!super.isConnected(event)) {
      const response = await this.doFetch(
        "/style/assign",
        "PATCH",
        JSON.stringify({
          authenticity_token: document.querySelector('meta[name="csrf-token"]')
            .content,
          paletteid: btn.dataset.stylepaletteid,
          stylable_clazz: dataprovider.dataset.stylableclass,
          stylable_id: dataprovider.dataset.stylableid,
        }),
      );
      if (response.ok) {
        let selector =
          "#" +
          dataprovider.dataset.stylableclass.toLowerCase() +
          "-" +
          dataprovider.dataset.stylableid +
          " .card";
        let stylable_element = document.querySelector(selector);
        let cl = stylable_element.classList;
        var cls = Array.from(cl).filter((c) => c.match(/style_palette_\d+$/));
        stylable_element.classList.remove(...cls);
        stylable_element.classList.add("style_palette_" + paletteid);
        this.closeDropDown();
        return true;
      }
      return false;
    }
    this.stimulate(
      "StylepalettesReflex#assignPalette",
      this.element,
      dataprovider.dataset,
      btn.dataset["stylepaletteid"],
    );
  }
  async addPalette(event) {
    let dataprovider = this.getParent(event.target, "stylableid", "dataset");
    if (!super.isConnected(event)) {
      //   get '/style/create/:clazz/:id', to: 'style_palettes#create', as: 'create_style_palette'
      const response = await this.doFetch(
        "/style/create",
        "POST",
        JSON.stringify({
          authenticity_token: document.querySelector('meta[name="csrf-token"]')
            .content,
          clazz: this.element.dataset.stylepalettesRobjclassValue,
          id: this.element.dataset.stylepalettesRobjidValue,
        }),
      );
      if (response.ok) {
        window.location.reload();
      }
      return true;
    }
    this.stimulate("StylepalettesReflex#addPalette", this.element);
  }

  deletePalette(event) {
    let button = super.getParent(event.target, "btn");
    let paletteId = button.dataset["palette-id"];
    let dPalette = this.element.querySelector(
      "[data-stylepaletteseditor-paletteid-value='" + paletteId + "']",
    );

    const ok = confirm(
      "Are you sure you would like to delete this StylePalette?",
    );
    if (ok) {
      if (!super.isConnected(event)) {
        this.fetchDeletePalette(paletteId);
        return true;
      }
      dPalette.remove();
      this.stimulate(
        "StylepalettesReflex#deletePalette",
        this.element,
        paletteId,
      );
    }
  }

  async fetchDeletePalette(paletteId) {
    const response = await super.doFetch(
      "/style/",
      "DELETE",
      JSON.stringify({
        authenticity_token: document.querySelector('meta[name="csrf-token"]')
          .content,
        paletteid: paletteId,
      }),
    );
    if (response.ok) {
      this.element
        .querySelector(
          "[data-stylepaletteseditor-paletteid-value='" + paletteId + "']",
        )
        .remove();
      return true;
    }
    return false;
  }

  changedPalette(event) {
    if (!super.isConnected(event)) {
      return true;
    }
    this.stimulate(
      "StylepalettesReflex#displayStyle",
      event.target,
      this.selectTarget.value,
    );
  }

  editStyles(event) {
    if (!super.isConnected(event)) {
      return false;
    }

    this.getModalController().show(event);
    this.getModalController().hideFooter();

    let trg = this.getParent(
      event.target,
      "stylepalettes-robjclass-value",
      "dataset",
    );
    this.stimulate("StylepalettesReflex#showStylesEditor");
  }
}
