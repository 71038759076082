import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex';

/* This is the custom StimulusReflex controller for the Posts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ "modal", "closeButton", "saveButton", "modalBody", "title", "footer" ]

  connect () {
    StimulusReflex.register(this)
  }

  startPresentation (event) {
    document.addEventListener('keydown', this.keyup)
    this.getModalController().showFullscreen(event)
    this.getModalController().hideFooter()
    this.stimulate('PostsPresentationReflex#startPresentation', this.getParent(event.target, 'button', 'tagname'))
  }

  keyup = (event) => {
    var code = event.keyCode;
    let listenKeys = {38:'up',40:'down',37:'left',39:'right'}

    if(listenKeys.hasOwnProperty(code)) {
      event.preventDefault()
      this.stimulateMove(listenKeys[code])
    }
  }
  move (event) {
    let btn = this.getParent(event.target, 'button', 'tagname')
    console.log("postpresentation_controller: move in dir: " + btn.dataset.dir)
    this.stimulateMove(btn.dataset.dir)
    event.preventDefault()
  }

  stimulateMove (directionstr) {
    let currentCard = document.querySelector('#postpresentation-animation-container')
    this.currentMove = directionstr
    this.slideOut(currentCard, function () {
        this.stimulate("PostsPresentationReflex#move", document.querySelector('.card-present'), this.currentMove)
      }.bind(this), 
      this.currentMove
    )
  }
  hexToRGB(hexColor) {
    hexColor = hexColor.replace(/^#/, '');

    var blue  = hexColor.substr(4, 2); 
    var green = hexColor.substr(2, 2);
    var red   = hexColor.substr(0, 2);

    return [parseInt(red, 16), parseInt(green, 16), parseInt(blue, 16)];
  }

  setForegroundColor(rgb) {
    console.log("setForegroundColor\n\trgb: " + rgb);
    var sum = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
    console.log("setForegroundColor sum: " + sum);
    return (sum > 128) ? 'black' : 'white';
  }

  updateModalHeaderBackgroundColor() {
    let bgColor = document.querySelector('.post-presentation-header').dataset.bgColor
    let textColor = this.setForegroundColor(this.hexToRGB(bgColor));
    let modalController = this.getModalController();

    document.querySelector('.modal-header').style.backgroundColor = bgColor;
    document.querySelector('.modal-header').style.color = textColor;

    modalController.closeEventHandlers.push( function () {
      document.querySelector('.modal-header').style.backgroundColor = '';
      document.querySelector('.modal-header').style.color = '';
    });
  }

  afterStartPresentation(element, reflex, error, reflexId) {
    this.updateModalHeaderBackgroundColor()
  }
  startPresentationSuccess(element, reflex, error, reflexId) {
    this.updateModalHeaderBackgroundColor()
  }
  moveSuccess(element, reflex, error, reflexId) {
    let currentCard = document.querySelector('#postpresentation-animation-container')
    this.slideIn(currentCard, function () {}, this.currentMove)
    this.updateModalHeaderBackgroundColor()
  }

  slideIn (element, callback, toDirection="right", duration=50, width=0) {
    if (element == undefined) {
      console.log("slideIn ERROR: element undefined")
      return false;
    }
    var op = 0;
    clearInterval(self.timer)
    element.style.display = 'block'

    self.timer = setInterval(function () {
      if(op >= 100) {
        clearInterval(self.timer)
      }

      var offset =  (100 - (op * 100 / 100));
      console.log("slideIn op: " + op + "dir: " + toDirection + " offset: " + offset);

      switch (toDirection) {
      case "left":
        element.style.marginLeft = offset + "vw";
        break;
      case "right":
        element.style.marginLeft = offset + "vw";
        break;
      case "down":
        element.style.marginTop = offset + "vh";
        break;
      case "up":
        element.style.marginTop = offset + "vh";
        break;
      }

      op += 20
    }, duration);
  }

  slideOut (element, callback, moveDirection="right", duration=50, width=0) {
    if (element == undefined) {
      console.log("slideOut ERROR: element undefined")
      return false;
    }
    var op = 0;
    clearInterval(self.timer)

    self.timer = setInterval(function () {
      let steps = 100;

      if(op >= steps) {
        clearInterval(self.timer)
      }

      let offset = "-" + (0 + (op * 100 / 100))
      console.log("slideOut " + element.id + " op: " + op + " toDirection" + moveDirection + " offset: " + offset)


      switch (moveDirection) {
      case "right":
        element.style.marginLeft =  offset + "vw";
        break;
      case "left":
        element.style.marginLeft =  offset + "vw";
        break;
      case "up":
        element.style.marginTop =  offset + "vh";
        break;
      case "down":
        element.style.marginTop =  offset + "vh";
        break;
      }

      op += 20
      if(op > 100) {
        console.log("cleared timer now run callback")
        callback()
      }
    }, duration);
    console.log("after timer")
  }


  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="posts" to your markup alongside
   * data-reflex="Posts#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Posts#dance" data-controller="posts">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Posts#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Posts#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = 'Danced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "Couldn't dance!"
  // }
}
