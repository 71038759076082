import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

export default class extends ApplicationController {
  static targets = ["filterable", "filterinput", "nofiltermatch"];

  connect() {
    super.connect();
    console.log("boardfilter did connect");
  }

  filter(e) {
    console.log("filter by: " + e.target.value);

    let items = document.querySelectorAll("li.post-item");
    let filterValue = e.target.value.toUpperCase().trim();
    var counter = 0;

    items.forEach(function (el, key, listObj) {
      let txtValue = el.textContent || el.innerText;
      // console.log(`Element ${el}:  ${txtValue} \n`);
      if (txtValue.toUpperCase().indexOf(filterValue) > -1) {
        el.style.display = "";
        counter++;
      } else {
        el.style.display = "none";
      }
    }, "arg");
    this.showNoMatch(counter == 0 && filterValue.length > 0);
  }
  showNoMatch(on) {
    if (on) {
      this.nofiltermatchTarget.classList.remove("d-none");
    } else {
      this.nofiltermatchTarget.classList.add("d-none");
    }
  }
}
